import React, { useEffect, useState } from "react";
import { ProjectRecord } from "@/types";
import { FormProvider, useForm } from "react-hook-form";
import Modal from "@/components/Modal";
import { StyledIconButton, StyledRightActionButton } from "@/components/styled";
import SvgUserEdit from "@/assets/icons/SVGUserEdit.tsx";
import ProjectForm from "@/pages/ProjectDetailsPage/components/ProjectForm.tsx";
import { AddProjectForm } from "@/pages/ProjectDetailsPage/components/AddProjectModal.tsx";
import { updateProject } from "@/api/projectsApi";
import { PROJECT_ALREADY_EXISTS_ERROR_MESSAGE } from "@/constants";
import SVGPlus from "@/assets/icons/SVGPlus.tsx";
import { useMediaQuery, useTheme } from "@mui/material";

type Props = {
  project: ProjectRecord;
  iconOnly?: boolean;
  getUpdatedProjects: () => void;
  getFilesReq?: () => void;
};

const EditProjectModal = ({
  project,
  getUpdatedProjects,
  getFilesReq,
  iconOnly = false,
}: Props) => {
  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);

  const [step, setStep] = useState<1 | 2>(1);
  const handleStep = (step: 1 | 2) => setStep(step);

  const methods = useForm<Partial<AddProjectForm>>({
    mode: "onChange",
  });
  const {
    handleSubmit,
    reset,
    control,
    watch,
    getValues,
    setError,
    formState: { isValid },
  } = methods;

  useEffect(() => {
    if (project) {
      reset(project as AddProjectForm);
    }
  }, [project, reset]);

  const handleClose = () => {
    setOpen(false);
    reset(project as AddProjectForm); // Reset to initial values on close
  };

  const handleFormChange = () => {
    const formData = getValues();
    const hasAnyChanges = Object.keys(formData).some(
      (key) =>
        formData[key as keyof AddProjectForm] !==
        project?.[key as keyof AddProjectForm],
    );
    setHasChanges(hasAnyChanges);
  };

  useEffect(() => {
    const subscription = watch(handleFormChange);
    return () => subscription.unsubscribe();
  }, [watch, project]);

  const getChangedValues = (
    formData: Partial<AddProjectForm>,
    initialData: ProjectRecord,
  ) => {
    const changedValues: Partial<AddProjectForm> = {};
    Object.keys(formData).forEach((key) => {
      if (
        formData[key as keyof AddProjectForm] !==
        initialData[key as keyof AddProjectForm]
      ) {
        changedValues[key as keyof AddProjectForm] =
          formData[key as keyof AddProjectForm];
      }
    });
    return changedValues;
  };

  const onSubmit = async (data: Partial<AddProjectForm>) => {
    try {
      const changedValues = getChangedValues(data, project);
      if (Object.keys(changedValues).length > 0) {
        const formData = new FormData();
        Object.keys(changedValues).forEach((key) => {
          const value = data[key as keyof Partial<AddProjectForm>];
          if (value !== undefined) {
            if (key === "upfrontIncentivePayment") {
              formData.append(
                key,
                `${Number(data?.upfrontIncentivePayment) * 100}`,
              );
            } else {
              // exclude comment field
              if (key !== "comment") {
                formData.append(key, value);
              }
            }
          }
        });
        await updateProject(project?.id, formData);
        getUpdatedProjects();

        if (getFilesReq) {
          getFilesReq();
        }
      }
      handleClose();
    } catch (e: any) {
      if (e?.response?.data?.error === PROJECT_ALREADY_EXISTS_ERROR_MESSAGE) {
        setError("customerName", { message: e?.response?.data?.error });
      } else {
        console.error(e);
      }
    }
  };

  return (
    <>
      <StyledRightActionButton
        sx={{
          gap: "6px",
          whiteSpace: "nowrap",
          minWidth: "initial",
          minHeight: "initial",
          flex: 1,
          ...(!downSm && { border: 0 }),
        }}
        onClick={() => setOpen(true)}
        variant={iconOnly ? "text" : downSm ? "outlined" : "text"}
      >
        {!downSm && <SvgUserEdit />}
        {iconOnly && downSm && <SvgUserEdit />}
        {downSm && !iconOnly && "Edit project"}
      </StyledRightActionButton>
      <Modal open={open} onClose={handleClose} title={"Edit project"}>
        <FormProvider {...methods}>
          <ProjectForm
            handleSubmit={handleSubmit(onSubmit)}
            handleClose={handleClose}
            isEdit={true}
            participantEnrollment={project?.participantEnrollment}
            contract={project?.contract}
            incentiveStatus={project?.incentiveStatus}
            systemSizingTool={project?.systemSizingTool}
            handleStep={handleStep}
            step={step}
          />
        </FormProvider>
      </Modal>
    </>
  );
};

export default EditProjectModal;
