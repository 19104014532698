import React, { useEffect } from "react";
import Layout from "@/components/Layout";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import ProjectInfoCard from "@/pages/ProjectDetailsPage/components/ProjectInfoCard.tsx";
import ProjectDocumentationTable from "@/pages/ProjectDetailsPage/components/ProjectDocumentationTable.tsx";
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useProjectsStore } from "@/store/projectsStore.ts";
import DeleteAccountCard from "@/pages/admin-pages/UserProfilePage/components/DeleteAccountCard.tsx";
import { deleteProjectReq } from "@/api/projectsApi";
import ProjectsNavigation from "@/pages/ProjectDetailsPage/components/ProjectsNavigation.tsx";
import { useAuthStore } from "@/store/authStore.ts";
import NoteSection from "@/pages/ProjectDetailsPage/components/NoteSection";

const ProjectDetailsPage = () => {
  const theme = useTheme();
  const role = useAuthStore((state) => state.role);

  const projectsIds = useProjectsStore((state) => state.projectsIds);

  const getProjectsIds = useProjectsStore((state) => state.getProjectsIds);

  const matchesDownLg = useMediaQuery(theme.breakpoints.down("xl"));
  const { id } = useParams();
  const getProjectDetails = useProjectsStore(
    (state) => state.getProjectDetails,
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      getProjectDetails(Number(id));
    }
  }, [id]);

  const handleDeleteProject = async () => {
    if (id) {
      try {
        await deleteProjectReq(Number(id));
        navigate("/projects");
      } catch (e) {
        console.error(e);
      }
    }
  };
  return (
    <Layout>
      <Box
        sx={{
          marginTop: "28px",
          display: "flex",
          width: "100%",
          flexDirection: "column",
          gap: "24px",
        }}
      >
        {matchesDownLg && (
          <Box sx={{ width: "100%", height: "48px", position: "relative" }}>
            <ProjectsNavigation
              navigateTo={(projectId, searchParams) => {
                navigate(`/projects/${projectId}?${searchParams.toString()}`);
              }}
              ids={projectsIds}
              getIds={getProjectsIds}
            />
          </Box>
        )}
        <ProjectDocumentationTable projectId={Number(id)} />
        {role === "administrator" && (
          <DeleteAccountCard
            onDelete={handleDeleteProject}
            buttonText={"Delete Project"}
            confirmationText={"I confirm project deletion"}
            headerText={"Delete Project"}
          />
        )}
        <NoteSection />
      </Box>
    </Layout>
  );
};

export default ProjectDetailsPage;
