import React, { useEffect } from "react";
import { Box } from "@mui/material";
import NoteItem from "@/pages/ProjectDetailsPage/components/NoteSection/NoteItem.tsx";
import { NoteType } from "@/types";
import { useNotesStore } from "@/store/noteStore.ts";
import { useParams } from "react-router-dom";

const NoteList = () => {
  const notes = useNotesStore((state) => state.notes);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
      {notes?.map((note) => <NoteItem key={note?.id} note={note} />)}
    </Box>
  );
};

export default NoteList;
