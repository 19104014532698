import { create } from "zustand";
import { deleteArticleReq } from "@/api/adminApi";
import { NoteType } from "@/types";
import { deleteNoteReq, getNotesReq } from "@/api/noteApi";

type State = {
  notes: NoteType[];
};

type Actions = {
  getNotes: (projectId: number) => void;
  createNote: (newNote: NoteType) => void;
  updateNote: (updatedNote: NoteType) => void;
  deleteNote: (noteId: number) => Promise<void>;
};

export const useNotesStore = create<State & Actions>((set, get) => ({
  notes: [],

  getNotes: async (projectId) => {
    try {
      const response = await getNotesReq(projectId);
      set(() => ({
        notes: response?.data?.comments,
      }));
    } catch (e) {
      console.error(e);
    }
  },

  createNote: (note) => {
    set((state) => ({
      notes: [note, ...state.notes],
    }));
  },

  updateNote: (updatedNote) => {
    set((state) => ({
      notes: state.notes.map((note) =>
        note.id === updatedNote?.id ? { ...note, ...updatedNote } : note,
      ),
    }));
  },

  deleteNote: async (noteId) => {
    const previousNotes = get().notes;

    set((state) => ({
      notes: state.notes.filter((note) => note.id !== noteId),
    }));

    try {
      await deleteNoteReq(noteId);
    } catch (e) {
      console.error(e);
      set(() => ({
        notes: previousNotes,
      }));
    }
  },
}));
