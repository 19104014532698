import React, { useMemo, useState } from "react";
import {
  Box,
  Modal as MuiModal,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { NoteType } from "@/types";
import SVGCreatedAt from "@/assets/icons/SVGCreatedAt.tsx";
import SVGUpdatedAt from "@/assets/icons/SVGUpdatedAt.tsx";
import SVGEdited from "@/assets/icons/SVGEdited.tsx";
import { StyledIconButton } from "@/components/styled";
import SVGUserEdit from "@/assets/icons/SVGUserEdit.tsx";
import SVGDelete from "@/assets/icons/SVGDelete.tsx";
import EditNote from "@/pages/ProjectDetailsPage/components/NoteSection/EditNote.tsx";
import DangerModal from "@/components/DangerModal";
import ProjectNoteMenu from "@/pages/ProjectDetailsPage/components/NoteSection/ProjectNoteMenu.tsx";
import { useAuthStore } from "@/store/authStore.ts";
import { useNotesStore } from "@/store/noteStore.ts";
import { useUserStore } from "@/store/userStore.ts";
import { formatDate } from "@/utils/formatDate.ts";

type Props = {
  note: NoteType;
};

const BoxNoteWrapper = styled(Box)(() => ({
  width: "100%",
  padding: "12px 16px",
  border: "1px solid #EDEDED",
  borderRadius: "12px",
  display: "flex",
  gap: "8px",
  justifyContent: "space-between",
}));

const BoxButtonsWrapper = styled(Box)(() => ({
  display: "flex",
  gap: "4px",
  marginRight: "-8px",
  marginTop: "-8px",
  height: "max-content",
}));

const BoxBottomInfoWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "12px",
  alignItems: "center",
  marginTop: "8px",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "start",
  },
}));

const NoteItem = ({ note }: Props) => {
  const deleteNote = useNotesStore((state) => state.deleteNote);
  const role = useAuthStore((state) => state.role);
  const userId = useUserStore((state) => state.user.id);
  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));

  const [editMode, setEditMode] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const closeEditMode = () => setEditMode(false);
  const handleCloseDeleteModal = () => setOpenDeleteModal(false);

  const handleDeleteNote = async () => {
    try {
      await deleteNote(note?.id);
    } catch (e) {
      console.error(e);
    }
  };

  const isNoteOwner = userId === note?.userId;

  const isActionsAllowed = useMemo(() => {
    return role === "administrator" || isNoteOwner;
  }, [role, isNoteOwner]);

  return (
    <>
      {editMode && isActionsAllowed && (
        <EditNote
          noteId={note?.id}
          noteValue={note?.note}
          closeEditMode={closeEditMode}
        />
      )}
      {!editMode && (
        <BoxNoteWrapper>
          <Box sx={{ display: "flex", gap: "8px", flexDirection: "column" }}>
            <Typography
              variant={"body1Small"}
              sx={{
                whiteSpace: "pre-wrap",
                wordBreak: "break-word",
                fontWeight: 400,
              }}
            >
              {note?.note}
            </Typography>
            <BoxBottomInfoWrapper>
              <IconWithInfoItem
                icon={<SVGCreatedAt />}
                text={formatDate(note?.createdAt)}
              />
              {note?.createdAt !== note?.updatedAt && (
                <IconWithInfoItem
                  icon={<SVGEdited />}
                  text={formatDate(note?.updatedAt)}
                />
              )}
              <IconWithInfoItem icon={<SVGUpdatedAt />} text={note?.userName} />
            </BoxBottomInfoWrapper>
          </Box>
          {!downSm && isActionsAllowed && (
            <BoxButtonsWrapper>
              <StyledIconButton onClick={() => setEditMode(true)}>
                <SVGUserEdit />
              </StyledIconButton>
              <StyledIconButton onClick={() => setOpenDeleteModal(true)}>
                <SVGDelete size={24} />
              </StyledIconButton>
            </BoxButtonsWrapper>
          )}
          {downSm && (
            <ProjectNoteMenu
              isActionsAllowed={isActionsAllowed}
              currentNote={note?.note}
              handleDeleteClick={() => setOpenDeleteModal(true)}
              handleEditClick={() => setEditMode(true)}
            />
          )}
        </BoxNoteWrapper>
      )}
      <MuiModal open={openDeleteModal} onClose={handleCloseDeleteModal}>
        <>
          <DangerModal
            onClick={handleDeleteNote}
            onClose={handleCloseDeleteModal}
            title={`Delete Note?`}
            subtitle={`This step cannot be undone.`}
            buttonText={"Delete"}
          />
        </>
      </MuiModal>
    </>
  );
};

export default NoteItem;

const IconWithInfoItem = ({
  icon,
  text,
}: {
  icon: JSX.Element;
  text: string;
}) => {
  return (
    <Box sx={{ display: "flex", gap: "4px", alignItems: "center" }}>
      {icon}
      <Typography variant={"subtitleRegular"} sx={{ fontSize: "12px" }}>
        {text}
      </Typography>
    </Box>
  );
};
