import axios from "@/libs/axios.ts";

export const createNoteReq = async (projectId: number, note: string) => {
  return axios.post(`/project/comment/create/${projectId}`, { note });
};
export const updateNoteReq = async (noteId: number, note: string) => {
  return axios.post(`/project/comment/update/${noteId}`, { note });
};
export const deleteNoteReq = async (noteId: number) => {
  return axios.delete(`/project/comment/delete/${noteId}`);
};
export const getNotesReq = async (projectId: number) => {
  return axios.get(`/project/comment/list/${projectId}`);
};
