import React, { useState } from "react";
import { StyledIconButton, StyledInput } from "@/components/styled";
import { Box } from "@mui/material";
import SVGEnterIcon from "@/assets/icons/SVGEnterIcon.tsx";
import { createNoteReq } from "@/api/noteApi";
import { useParams } from "react-router-dom";
import { useNotesStore } from "@/store/noteStore.ts";

const AddNote = () => {
  const { id } = useParams();
  const createNote = useNotesStore((state) => state.createNote);
  const [value, setValue] = useState("");
  const onChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setValue(e.target.value);
  };

  const handleAddNewNote = async () => {
    if (value?.length) {
      try {
        const response = await createNoteReq(Number(id), value);
        createNote(response.data?.comment);
        setValue("");
      } catch (e) {
        console.error(e);
      }
    }
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    if (e.key === "Enter" && e.metaKey) {
      handleAddNewNote();
    }
  };
  return (
    <Box sx={{ position: "relative", width: "100%", marginBottom: "24px" }}>
      <StyledInput
        onChange={onChange}
        value={value}
        placeholder={"Add note.."}
        fullWidth
        multiline
        rows={4}
        onKeyDown={handleKeyDown}
        sx={{ "& .MuiInputBase-input": { paddingRight: "50px" } }}
      />
      <StyledIconButton
        onClick={handleAddNewNote}
        sx={{
          position: "absolute",
          right: "4px",
          bottom: "50%",
          transform: "translateY(50%)",
        }}
      >
        <SVGEnterIcon />
      </StyledIconButton>
    </Box>
  );
};

export default AddNote;
