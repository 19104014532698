import React from "react";

const SvgEdited = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.71975 9.53044L9.53025 4.71994C9.8235 4.42669 9.8235 3.95194 9.53025 3.65944L8.34075 2.46994C8.0475 2.17669 7.57275 2.17669 7.28025 2.46994L2.46975 7.28044C2.32875 7.42144 2.25 7.61194 2.25 7.81069V9.75019H4.1895C4.38825 9.75019 4.57875 9.67144 4.71975 9.53044V9.53044Z"
        stroke="#716F6D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 9.75H15.75"
        stroke="#716F6D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.25 12.75H15.75"
        stroke="#716F6D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.25 15.75H15.75"
        stroke="#716F6D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SvgEdited;
