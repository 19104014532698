import React, { ReactNode, useState } from "react";
import { Box, Button, MenuItem, Snackbar, Typography } from "@mui/material";
import SVGDots from "@/assets/icons/SVGDots.tsx";
import { StyledMenu } from "@/components/styled";
import SVGCopyText from "@/assets/icons/SVGCopyText.tsx";
import SVGEditNote from "@/assets/icons/SVGEditNote.tsx";
import SVGDelete from "@/assets/icons/SVGDelete.tsx";

type Props = {
  isActionsAllowed: boolean;
  currentNote: string;
  handleEditClick: () => void;
  handleDeleteClick: () => void;
};

const ProjectNoteMenu = ({
  isActionsAllowed,
  currentNote,
  handleDeleteClick,
  handleEditClick,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const openMenu = Boolean(anchorEl);
  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleCopyText = () => {
    if (currentNote) {
      navigator.clipboard
        .writeText(currentNote)
        .then(() => {
          setOpenSnackbar(true);
        })
        .catch((err) => {
          console.error("Failed to copy note: ", err);
        });
    }
    handleCloseMenu();
  };

  return (
    <>
      <Button
        onClick={handleOpenMenu}
        sx={{ padding: 0, minWidth: "initial", height: "max-content" }}
        variant={"text"}
      >
        <SVGDots />
      </Button>
      <StyledMenu
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleCloseMenu}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <NoteMenuItem
          icon={<SVGCopyText />}
          onClick={handleCopyText}
          text={"Copy text"}
        />
        {isActionsAllowed && (
          <NoteMenuItem
            icon={<SVGEditNote />}
            onClick={handleEditClick}
            text={"Edit note"}
          />
        )}
        {isActionsAllowed && (
          <NoteMenuItem
            icon={<SVGDelete />}
            onClick={handleDeleteClick}
            text={"Delete note"}
            textColor={"#FF4D00"}
          />
        )}
      </StyledMenu>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        message="Copied"
        autoHideDuration={800}
      />
    </>
  );
};

export default ProjectNoteMenu;

const NoteMenuItem = ({
  icon,
  text,
  onClick,
  textColor,
}: {
  icon: ReactNode;
  text: string;
  textColor?: string;
  onClick: () => void;
}) => {
  return (
    <MenuItem onClick={onClick}>
      <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
        {icon}
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: 600,
            ...(textColor && { color: textColor }),
          }}
        >
          {text}
        </Typography>
      </Box>
    </MenuItem>
  );
};
