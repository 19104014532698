import React, { useState } from "react";
import { Box } from "@mui/material";
import { StyledIconButton, StyledInput } from "@/components/styled";
import SVGEnterIcon from "@/assets/icons/SVGEnterIcon.tsx";
import SVGCheckMark from "@/assets/icons/SVGCheckMark.tsx";
import SVGCross from "@/assets/icons/SVGCross.tsx";
import SVGSimpleCheckMark from "@/assets/icons/SVGSimpleCheckMark.tsx";
import { updateNoteReq } from "@/api/noteApi";
import { useNotesStore } from "@/store/noteStore.ts";

type Props = {
  noteValue: string;
  noteId: number;
  closeEditMode: () => void;
};

const EditNote = ({ noteId, noteValue, closeEditMode }: Props) => {
  const updateNote = useNotesStore((state) => state.updateNote);
  const [value, setValue] = useState(noteValue);
  const onChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setValue(e.target.value);
  };

  const handleSaveNote = async () => {
    try {
      const response = await updateNoteReq(noteId, value);
      updateNote(response.data?.comment);
      closeEditMode();
    } catch (e) {
      console.error(e);
    }
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    if (e.key === "Enter" && e.metaKey) {
      handleSaveNote();
    }
  };
  return (
    <Box sx={{ position: "relative", width: "100%" }}>
      <StyledInput
        onChange={onChange}
        value={value}
        fullWidth
        multiline
        rows={4}
        autoFocus
        onKeyDown={handleKeyDown}
        sx={{ "& .MuiInputBase-input": { paddingRight: "90px" } }}
      />
      <Box
        sx={{
          position: "absolute",
          right: "4px",
          top: "8px",
          display: "flex",
        }}
      >
        <StyledIconButton onClick={handleSaveNote}>
          <SVGSimpleCheckMark />
        </StyledIconButton>
        <StyledIconButton onClick={closeEditMode}>
          <SVGCross color={"#FF4D00"} />
        </StyledIconButton>
      </Box>
    </Box>
  );
};

export default EditNote;
